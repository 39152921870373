import { Component, OnInit } from '@angular/core';

export enum SnapshotType {
  BABIES = 1,
  PLANTED = 2,
  ADOPTED = 3,
  AVAILABLE = 4
}

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {

  snapshots: {type: SnapshotType, value: number}[] = [];

  constructor() { }

  ngOnInit() {
    this.snapshots = [
      { type: SnapshotType.BABIES, value: 8634 },
      { type: SnapshotType.PLANTED, value: 5634 },
      { type: SnapshotType.ADOPTED, value: 487 },
      { type: SnapshotType.AVAILABLE, value: 34 },
    ];
  }

}
