<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">
    
    <!-- main toolbar -->
    <mat-toolbar>
      <mat-toolbar-row class="main-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
        <span (click)="drawer.toggle()"><i class="icon-lg fas fa-bars"></i></span>
      </mat-toolbar-row>
    </mat-toolbar>

    <!-- logo -->
    <div class="logo">
      <img src="assets/images/logo_extended.svg">
    </div>

    <!-- snapshots -->
    <div class="snapshots-container">
      <app-snapshot></app-snapshot>
    </div>

    <!-- map explorer -->
    <div class="map-hint">
      <div>
        <img src="assets/images/trees/icon_green.svg">
      </div>

      <div class="dark-blue"> 
        <span>{{ 'DASHBOARD.MAP.NAVIGATOR.TITLE' | translate }}</span>
        <span (click)="navigate('/map-view')">{{ 'DASHBOARD.MAP.NAVIGATOR.BTN' | translate }}&nbsp; <i class="fas fa-chevron-right"></i></span>
      </div>

    </div>

    <!-- news -->
    <span class="news-title"><i class="blue fas fa-newspaper"></i>&nbsp;{{ 'DASHBOARD.NEWS.TITLE' | translate }}</span>
    <div class="news-container">
      <app-newsletter [newsletter]="newsletter" *ngFor="let newsletter of newsletters"></app-newsletter>
    </div>

  </mat-drawer-content>
</mat-drawer-container>



