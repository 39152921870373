<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">

    <!-- title -->
    <div class="font-lg dark-blue">{{ 'MY.TREES.TITLE' | translate }}</div>

    <div class="tree-list">
      <div *ngFor="let tree of trees; let i = index">
        <ng-content *ngTemplateOutlet="treeTile; context: {tree: tree}"></ng-content>

        <hr *ngIf="i !== trees.length - 1">
      </div>

      <div *ngIf="trees.length === 0" class="no-trees">
        <span>{{ 'MY.TREES.NO.TREES' | translate }}</span>
      </div>
      
    </div>


    
    <!-- back button -->
    <a class="close-btn dark-gray" mat-button [routerLink]="'/dashboard'">
      <span><i class="fas fa-chevron-left"></i>&nbsp;{{ 'MY.TREES.GO.BACK.BTN' | translate }}</span>
    </a>
    
  </mat-drawer-content>
</mat-drawer-container>


<!-- tree entry -->
<ng-template #treeTile let-tree="tree">
  <div class="tree-item" [routerLink]="'/map-view'" [queryParams]="{ treeId: tree.id }">
    <div>
      <img src="assets/images/trees/icon_green.svg">
    </div>

    <div class="dark-blue"> 
      <span class="font-md">{{ tree.name }}</span>
      <span>
        <span class="font-bold">{{ 'MY.TREES.LATITUDE.BTN' | translate }}:&nbsp;</span><span class="latitude-span">{{ tree.latitude.toFixed(4) }}</span>

        <span class="font-bold">{{ 'MY.TREES.LONGITUDE.BTN' | translate }}:&nbsp;</span><span>{{ tree.latitude.toFixed(4) }}</span>
      </span>
    </div>

  </div>
</ng-template>