import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../core/api.service';
import { Tree } from '../core/models/tree';
import { TreeResult } from '../core/models/treeresult';
import { User } from '../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class MyTreesResolverService implements Resolve<Tree[]> {

  constructor(
    private apiService: ApiService,
    private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tree[]> | Observable<never> {
    const user: User = this.authService.user$.value;

    // load all trees of this user
    return this.apiService.loadTrees(user)
      .pipe(
        mergeMap((value: TreeResult) => {
          if(TreeResult.ok(value)) {
            const trees = value.data as Tree[];
            return of(trees);
          }
          return EMPTY;
        })      
      )
     
  }
}
