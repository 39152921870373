import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import MarkerClusterer from '@google/markerclustererplus';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiService } from 'src/app/core/api.service';
import { Tree, TreeType } from 'src/app/core/models/tree';
import { TreeResult } from 'src/app/core/models/treeresult';
import { SystemService } from 'src/app/core/system.service';


@Component({
  selector: 'app-google-tree-map',
  templateUrl: './google-tree-map.component.html',
  styleUrls: ['./google-tree-map.component.scss']
})
export class GoogleTreeMapComponent implements OnInit {
  @Input() triggerTreeId: number;
  @Output() adoptTree = new EventEmitter<{ tree: Tree, marker: any }>();
  
  // trees
  trees: Tree[] = [];
  treeType = TreeType;

  // center Odivelas
  center: {lat: number, lng: number} = { lat: 38.7954, lng: -9.1852  }
  zoom: number = 12;

  // tree icons
  icons: any  = {};
  selectionIcons: any  = {};

  top: number;

  markers = new Map<number, { tree: Tree, marker: any }>();

  ready: boolean;

  selectedTreeId: number;

  @ViewChild('treeInfo') treeInfo: TemplateRef<any>;
  @ViewChild('infoWindow', {read: ViewContainerRef}) infoWindow: ViewContainerRef;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private mapsAPILoader: MapsAPILoader,
    private systemService: SystemService) { }

  ngOnInit() {
    // mark app as loading state
    this.systemService.loading$.next(true);

    // set up center at the tree if we have it
    if(!!this.triggerTreeId) {
      this.zoom = 20
    }

    this.mapsAPILoader.load()
      .then(() => {

        this.setupIcons();

        // trees collection
        this.apiService.loadMapTrees()
          .subscribe((value: TreeResult) => {

            // mark app as loading state
            this.systemService.loading$.next(true);
            
            if(TreeResult.ok(value)) {
              const trees = value.data as Tree[];
              this.trees = trees;

              // setup map
              this.initMap();
            }
          })
        
      })    
  }

  initMap(){
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: this.zoom,
        center: this.center,
        disableDefaultUI: true,
        clickableIcons: false
      }
    );
    
    map.addListener("dragend", () => {      
      window.setTimeout(() => {
        this.infoWindow.remove();
        
        this.restoreTreeIcon();
      }, 300);
    });
    
    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    const markers = this.trees.map((tree: Tree, i) => {
      const marker = new google.maps.Marker({
        position: { lat: tree.latitude, lng: tree.longitude },
        icon: this.getTreeIcon(tree),
      });

      // info window
      const infowindow = new google.maps.InfoWindow({
        content: '',
      });


      marker.addListener("click", () => {

        map.setCenter(marker.getPosition());
        this.infoWindow.remove();
        const treeInfo = this.treeInfo.createEmbeddedView({tree: tree})
        this.infoWindow.insert(treeInfo)
        
        setTimeout(() => {
          var scale = Math.pow(2, map.getZoom());
          var nw = new google.maps.LatLng(
              map.getBounds().getNorthEast().lat(),
              map.getBounds().getSouthWest().lng()
          );
          var worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
          var worldCoordinate = map.getProjection().fromLatLngToPoint(marker.getPosition());
          var pixelOffset = new google.maps.Point(
              Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
              Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
          );

          const top = pixelOffset.x - 150;
          this.top = top;

        })

        // reset treeicon previouly selected        
        this.restoreTreeIcon();
        // change marker icon when selected
        this.selectedTreeId = tree.id;
        marker.setIcon(this.getSelectedTreeIcon(tree));

      });

      // add to dictionaty
      this.markers.set(tree.id, { tree: tree, marker: marker});

      return marker;

    });
    
    // Add a marker clusterer to manage the markers.
    // @ts-ignore MarkerClusterer defined via script
    new MarkerClusterer(map, markers, {
      imagePath:'assets/images/trees/icon_green_group_m',
      imageExtension: 'svg',
      imageSizes: [60,60,60,60,60,60],
      maxZoom: 19
    });


    // map ready
    google.maps.event.addListenerOnce(map, 'idle', function(){
      // do something only the first time the map is loaded
      this.ready = true;

      this.systemService.loading$.next(false);

      // now trigger info window, if we have specific tree coordinate
      if(!!this.triggerTreeId) {
        const treeMarker = this.markers.get(this.triggerTreeId.toString());        
        google.maps.event.trigger(treeMarker.marker, 'click')
      }

    }.bind(this));

  }

  setupIcons() {
    // default icons
    this.icons = {
      available: {
        icon: {
          url: "assets/images/trees/icon_blue_map.svg",
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      adopted: {
        icon: {
          url: 'assets/images/trees/icon_green_map.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      historical: {       
        icon: {
          url: 'assets/images/trees/icon_brown_map.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      garden: {
        icon: {
          url: 'assets/images/trees/icon_darkgreen_map.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      }
    }

    // selected tree icons
    this.selectionIcons = {
      available: {
        icon: {
          url: "assets/images/trees/icon_blue_map_selected.svg",
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      adopted: {
        icon: {
          url: 'assets/images/trees/icon_green_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      historical: {       
        icon: {
          url: 'assets/images/trees/icon_brown_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      garden: {
        icon: {
          url: 'assets/images/trees/icon_darkgreen_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      }
    }
  }

  private getTreeIcon(tree: Tree) {
    switch (tree.type) {
      case TreeType.AVAILABLE: 
        return this.icons.available.icon;

      case TreeType.ADOPTED: 
        return this.icons.adopted.icon;

      case TreeType.HISTORICAL: 
        return this.icons.historical.icon;

      case TreeType.GARDEN: 
        return this.icons.garden.icon;    
    }
  }

  private getSelectedTreeIcon(tree: Tree) {

    switch (tree.type) {
      case TreeType.AVAILABLE: 
        return this.selectionIcons.available.icon;

      case TreeType.ADOPTED: 
        return this.selectionIcons.adopted.icon;

      case TreeType.HISTORICAL: 
        return this.selectionIcons.historical.icon;

      case TreeType.GARDEN: 
        return this.selectionIcons.garden.icon;    
    }  
  }

  disbandInfoWindow(){
    this.restoreTreeIcon();
    this.infoWindow.remove();
  }

  
  private restoreTreeIcon() {
    // restore tree icon
    if(this.selectedTreeId > 0) {
      const {tree, marker} = this.markers.get(this.selectedTreeId);
      marker.setIcon(this.getTreeIcon(tree));
      this.selectedTreeId = -1;
    } 
  }

}