<div class="footer green-background">

  <div class="menu-icon white large" *ngIf="!!user" (click)="navigate('user', 'my-trees')" [ngClass]="activatedRoute === '/user/my-trees' ?  'active-link' : ''">
    <i class="icon-lg fas fa-tree"></i>
    <span>{{ 'FOOTER.MYTREES.BTN' | translate }}</span>
  </div>

  <!-- logo -->
  <div class="logo-container" (click)="navigate('dashboard')">
    <img src="assets/images/logo.svg">

  </div>

  <span class="spacer"></span>

  <div class="menu-icon white" (click)="navigate('map-view')" [ngClass]="activatedRoute === '/map-view' ?  'active-link' : ''">
    <i class="icon-lg fas fa-map-marker-alt"></i>
    <span>{{ 'FOOTER.MAP.BTN' | translate }}</span>
  </div>

  <div class="menu-icon white" (click)="navigate('news')" [ngClass]="activatedRoute === '/news' ?  'active-link' : ''">
    <i class="icon-lg fas fa-newspaper"></i>
    <span>{{ 'FOOTER.NEWS.BTN' | translate }}</span>
  </div>

</div>