import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  }

  setItem(key: string, item: any): any {
    !!item 
      ? localStorage.setItem(key, JSON.stringify(item))
      : localStorage.removeItem(key)    
  }

}
