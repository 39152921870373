import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { MyTreesComponent } from './my-trees/my-trees.component';
import { ProfileComponent } from './profile/profile.component';
import { MyTreesResolverService } from './my-trees-resolver.service';


const routes: Routes = [  
  {
    path: 'my-trees',
    component: MyTreesComponent,
    canActivate: [AuthGuard],
    resolve: {
      trees: MyTreesResolverService
    },
  },
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
