import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiService } from '../core/api.service';
import { Tree } from '../core/models/tree';
import { TreeResult } from '../core/models/treeresult';

@Injectable({
  providedIn: 'root'
})
export class TreeDetailResolverService implements Resolve<Tree> {

  constructor(
    private apiService: ApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tree> | Observable<never> {
    const id = +route.paramMap.get('id');

    // load tree info
    return this.apiService.loadTree(id)
      .pipe(
        mergeMap((value: TreeResult) => {
          if(TreeResult.ok(value)) {
            const tree = value.data[0] as Tree;
            return of(tree);
          }
          return EMPTY;
        })      
      )     
  }
}