import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapViewRoutingModule } from './map-view-routing.module';
import { NavigatorComponent } from './navigator/navigator.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [NavigatorComponent],
  imports: [
    CommonModule,
    MapViewRoutingModule,
    SharedModule
  ]
})
export class MapViewModule { }
