import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { CryptService } from './crypt.service';
import { Newsletter } from './models/newsletter';
import { Tree, TreeLocations, TreeType } from './models/tree';
import { TreeResult } from './models/treeresult';
import { User } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly API_SERVICE = 'https://arvore.makeitdigital.pt/api';

  constructor(
    private http: HttpClient,
    private cryptService: CryptService) { }

  login(email: string, password: string): Observable<any> {
    const body = {
      emailInput: email,
      passwordInput: this.cryptService.encrypt(password),
    }

    // mais@com.pt   12345

    const url = this.API_SERVICE + '/loginUser';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    const user: User = Object.assign(new User(), {
      userId: 1,
      email: 'mp@test.pt',
      name: 'Miguel'
    })
   return of(
     Object.assign(new TreeResult(), {code: 0, data: user})
   )
  }

  loginByToken(token: string): Observable<TreeResult> {    
    const body = {      
      token: token
    }

    // mais@com.pt   12345

    const url = this.API_SERVICE + '/logInByToken';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )
  }

  signUp(user: User): Observable<any> {   
    const body = {
     ...user,
     password: this.cryptService.encrypt(user.password),
    }
    
    const url = this.API_SERVICE + '/signinUser';
    
    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    console.log(JSON.stringify(user));
    return of(
      Object.assign(new TreeResult(), {code: 0, data: user})
    );
  }

  // my trees
  
  // load user trees
  loadTrees(user: User): Observable<TreeResult> {
    const body = {
      id_user: user.userId,
      token: user.token
    }

    const url = this.API_SERVICE + '/getMyTrees';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    const result = Object.assign(new TreeResult(), {
      data: [
        Object.assign(new Tree(), {name: 'Arvore 1', id: 1, latitude: 38.791115147105, longitude: -9.18672617091349}),
        Object.assign(new Tree(), {name: 'Arvore 2', id: "5920", latitude: 38.8169192429239, longitude: -9.23489995766625}),
        Object.assign(new Tree(), {name: 'Arvore 3', id: "5921", latitude: 38.8168922238248, longitude: -9.2348995413169}),
        Object.assign(new Tree(), {name: 'Arvore 4', id: "5924", latitude: 38.8151329410649, longitude: -9.23328357511469}),
        Object.assign(new Tree(), {name: 'Arvore 5', id: "5926", latitude: 38.8151788401984, longitude: -9.2331921735477}),
        Object.assign(new Tree(), {name: 'Arvore 6', id: "5925", latitude: 38.8151514958607, longitude: -9.23322629326501}),
        Object.assign(new Tree(), {name: 'Arvore 7', id: "5843", latitude: 38.8130252323434, longitude: -9.23327417415611}),
        Object.assign(new Tree(), {name: 'Arvore 8', id: "5841", latitude: 38.8130813301293, longitude: -9.23305628767202})
      ],
      code: 0
    })

    return of(result);
  }

  // newsletter
  // load all available news
  loadNews(): Observable<TreeResult> {
    const body = {
      id: -1
    }

    const url = this.API_SERVICE + '/getNews';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    const result = Object.assign(new TreeResult(), {
      data: [
        Object.assign(new Newsletter(), {title: 'Notícia 1', id: 1, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/05/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 2', id: 2, thumbnail: 'assets/images/newsletter_thumb.png', date: '02/05/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 3', id: 3, thumbnail: 'assets/images/newsletter_thumb.png', date: '01/05/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 4', id: 4, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/04/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 5', id: 5, thumbnail: 'assets/images/newsletter_thumb.png', date: '05/04/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 6', id: 6, thumbnail: 'assets/images/newsletter_thumb.png', date: '15/03/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 7', id: 7, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/03/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 8', id: 8, thumbnail: 'assets/images/newsletter_thumb.png', date: '01/03/2021'}),
        Object.assign(new Newsletter(), {title: 'Notícia 9', id: 9, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/02/2021'})
      ],
      code: 0
    })
    console.log(JSON.stringify(result))
    return of(result);
  }

  // load specific newsletter
  loadNewsletter(id: number): Observable<TreeResult> {
    const body = {
      id: id
    }

    const url = this.API_SERVICE + '/getNews';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    const newsList = [
      Object.assign(new Newsletter(), {title: 'Notícia 1', id: 1, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/05/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 2', id: 2, thumbnail: 'assets/images/newsletter_thumb.png', date: '02/05/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 3', id: 3, thumbnail: 'assets/images/newsletter_thumb.png', date: '01/05/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 4', id: 4, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/04/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 5', id: 5, thumbnail: 'assets/images/newsletter_thumb.png', date: '05/04/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 6', id: 6, thumbnail: 'assets/images/newsletter_thumb.png', date: '15/03/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 7', id: 7, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/03/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 8', id: 8, thumbnail: 'assets/images/newsletter_thumb.png', date: '01/03/2021'}),
      Object.assign(new Newsletter(), {title: 'Notícia 9', id: 9, thumbnail: 'assets/images/newsletter_thumb.png', date: '12/02/2021'})
    ]

    newsList.forEach(news => news.content = `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
      in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
      sunt in culpa qui officia deserunt mollit anim id est
      laborum.

      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
      rem aperiam, eaque ipsa quae ab illo inventore veritatis
      et quasi architecto beatae vitae dicta sunt explicabo.
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
      dolores eos qui ratione voluptatem sequi nesciunt.
      Neque porro quisquam est, qui dolorem ipsum quia
      dolor sit amet, consectetur, adipisci velit, sed quia non
      numquam eius modi tempora incidunt ut labore et
      dolore magnam aliquam quaerat voluptatem. Ut enim
      ad minima veniam, quis nostrum exercitationem ullam
      corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
      consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`)

    const result = Object.assign(new TreeResult(), {
      data: newsList.find((value: Newsletter) => value.id === id),
      code: 0
    })

    console.log(JSON.stringify(result))

    return of(result);
  }

  // load a specific tree info
  loadTree(id: number): Observable<TreeResult> {
    const body = {
      id: id
    }

    const url = this.API_SERVICE + '/getTree';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )



    // simulate a load long
    return this.loadMapTrees()
      .pipe(
        map( _ => {

          let  trees = [
            Object.assign(new Tree(), {name: 'Arvore 1', id: 1, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 2', id: 2, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 3', id: 3, latitude: 55.22, longitude: 21.22}),
            Object.assign(new Tree(), {name: 'Arvore 4', id: 4, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 5', id: 5, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 6', id: 6, latitude: 55.22, longitude: 21.22}),
            Object.assign(new Tree(), {name: 'Arvore 7', id: 7, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 8', id: 8, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 9', id: 9, latitude: 55.22, longitude: 21.22})
          ]
      
          trees = trees.map(tree => {
            return {
              ...tree,
              thumbnail: 'https://www.infoescola.com/wp-content/uploads/2013/09/oliveira.jpg',
              labName: "Olea europaea L.",
              family: "Oleáceas",
              origin: "Bacia do Mediterrânio",
              description: `Árvore de folha permanente que pode atingir entre os 5
              e os 15 metros de altura. O tronco é geralmente assimétrico e irregular (retorcido) de cor acinzentada. As
              raízes são muito fortes e poderosas, estendendo-se em
              profundidade.`,
              useCases: `A oliveira produz azeitonas que podem ser comidas
              depois de tratadas ou usadas para fazer azeite. `,
              curiosities: `A longevidade das oliveiras é grande. Existem oliveiras
              com mais de 2 500 anos de idade.
              Na freguesia de Santa Iria de Azoia, Loures existe uma
              das oliveiras mais antigas de Portugal com cerca de 2
              850 anos.
              No concelho de Odivelas existe uma localidade chamada Olival Basto, que tinha nas suas encostas olivais,
              onde vinham homens e mulheres de longe para a
              apanha da azeitona.`,
              photos: [
                'https://4.bp.blogspot.com/-UWJUkds4IbE/Vtcn_KjVrmI/AAAAAAAAWxE/vpyGtE94aUs/s1600/Oliveira%252520-%252520Ao%252520Natural.jpg',
                'https://live.staticflickr.com/3800/9552132355_59c9cb6e04_b.jpg',
                'https://maisribatejo.pt/wp-content/uploads/2019/11/oliveira-mouriscas-arvore-ano-2019.jpg'
              ],
              locations: [
                Object.assign(new TreeLocations(), {name: 'Olival Basto', id: 4, thumbnail: 'https://meuverdejardim.com.br/wp-content/uploads/2018/10/%C3%A1rvore-de-azeitonas.jpg', latitude: 12.22, longitude: 2.22}),
                Object.assign(new TreeLocations(), {name: 'Arvore 5', id: 5, thumbnail: 'https://live.staticflickr.com/3800/9552132355_59c9cb6e04_b.jpg', latitude: 32.22, longitude: 22.22}),
                Object.assign(new TreeLocations(), {name: 'Arvore 6', id: 6, thumbnail: 'https://i.pinimg.com/736x/f0/ad/0d/f0ad0db4a952af748df742e2854fb5ad.jpg', latitude: 55.22, longitude: 21.22})
              ]
            }
          })
      
          const result = Object.assign(new TreeResult(), {
            data: trees.find((value: Tree) => value.id === 1),// id),
            code: 0
          })
      
          //console.log(JSON.stringify(result))
      
          return result;
        })
      )

      /*
      
         let  trees = [
            Object.assign(new Tree(), {name: 'Arvore 1', id: 1, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 2', id: 2, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 3', id: 3, latitude: 55.22, longitude: 21.22}),
            Object.assign(new Tree(), {name: 'Arvore 4', id: 4, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 5', id: 5, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 6', id: 6, latitude: 55.22, longitude: 21.22}),
            Object.assign(new Tree(), {name: 'Arvore 7', id: 7, latitude: 12.22, longitude: 2.22}),
            Object.assign(new Tree(), {name: 'Arvore 8', id: 8, latitude: 32.22, longitude: 22.22}),
            Object.assign(new Tree(), {name: 'Arvore 9', id: 9, latitude: 55.22, longitude: 21.22})
          ]
      
          trees = trees.map(tree => {
            return {
              ...tree,
              thumbnail: 'https://www.infoescola.com/wp-content/uploads/2013/09/oliveira.jpg',
              labName: "Olea europaea L.",
              family: "Oleáceas",
              origin: "Bacia do Mediterrânio",
              description: `Árvore de folha permanente que pode atingir entre os 5
              e os 15 metros de altura. O tronco é geralmente assimétrico e irregular (retorcido) de cor acinzentada. As
              raízes são muito fortes e poderosas, estendendo-se em
              profundidade.`,
              useCases: `A oliveira produz azeitonas que podem ser comidas
              depois de tratadas ou usadas para fazer azeite. `,
              curiosities: `A longevidade das oliveiras é grande. Existem oliveiras
              com mais de 2 500 anos de idade.
              Na freguesia de Santa Iria de Azoia, Loures existe uma
              das oliveiras mais antigas de Portugal com cerca de 2
              850 anos.
              No concelho de Odivelas existe uma localidade chamada Olival Basto, que tinha nas suas encostas olivais,
              onde vinham homens e mulheres de longe para a
              apanha da azeitona.`,
              photos: [
                'https://4.bp.blogspot.com/-UWJUkds4IbE/Vtcn_KjVrmI/AAAAAAAAWxE/vpyGtE94aUs/s1600/Oliveira%252520-%252520Ao%252520Natural.jpg',
                'https://live.staticflickr.com/3800/9552132355_59c9cb6e04_b.jpg',
                'https://maisribatejo.pt/wp-content/uploads/2019/11/oliveira-mouriscas-arvore-ano-2019.jpg'
              ],
              locations: [
                Object.assign(new TreeLocations(), {name: 'Olival Basto', id: 4, thumbnail: 'https://meuverdejardim.com.br/wp-content/uploads/2018/10/%C3%A1rvore-de-azeitonas.jpg', latitude: 12.22, longitude: 2.22}),
                Object.assign(new TreeLocations(), {name: 'Arvore 5', id: 5, thumbnail: 'https://live.staticflickr.com/3800/9552132355_59c9cb6e04_b.jpg', latitude: 32.22, longitude: 22.22}),
                Object.assign(new TreeLocations(), {name: 'Arvore 6', id: 6, thumbnail: 'https://i.pinimg.com/736x/f0/ad/0d/f0ad0db4a952af748df742e2854fb5ad.jpg', latitude: 55.22, longitude: 21.22})
              ]
            }
          })
      
          const result = Object.assign(new TreeResult(), {
            data: trees.find((value: Tree) => value.id === 1),// id),
            code: 0
          })
      
          //console.log(JSON.stringify(result))
      
          return of(result);
      
      */
   
  }

  // map
  // load all trees for map
  loadMapTrees(): Observable<TreeResult> {
    const url = this.API_SERVICE + '/loadMapTrees?rand=' + Math.random();

    return this.http.get(url)
      .pipe(        
        map(value => value as TreeResult)        
      )

    const result = Object.assign(new TreeResult(), {
      data: [
        Object.assign(new Tree(), {name: 'Arvore 1', labName: "Olea europaea L.1", id: 1, latitude: 38.7559151, longitude: -9.1242284, type: TreeType.AVAILABLE}),
        Object.assign(new Tree(), {name: 'Arvore 2', labName: "Olea europaea L.2", id: 2, latitude: 38.7259253, longitude: -9.1362284, type: TreeType.AVAILABLE}),
        Object.assign(new Tree(), {name: 'Arvore 3', labName: "Olea europaea L.3", id: 3, latitude: 38.5359342, longitude: -9.1322284, type: TreeType.ADOPTED, ownerId: 1, ownerName: 'Anónimo'}),
        Object.assign(new Tree(), {name: 'Arvore 4', labName: "Olea europaea L.4", id: 4, latitude: 38.8359422, longitude: -9.1162284, type: TreeType.AVAILABLE}),
        Object.assign(new Tree(), {name: 'Arvore 5', labName: "Olea europaea L.5", id: 5, latitude: 38.7259553, longitude: -9.1699581, type: TreeType.ADOPTED, ownerId: 1, ownerName: 'Anónimo'}),
        Object.assign(new Tree(), {name: 'Arvore 6', labName: "Olea europaea L.6", id: 6, latitude: 38.5359612, longitude: -9.1446284, type: TreeType.ADOPTED, ownerId: 1, ownerName: 'Anónimo'}),
        Object.assign(new Tree(), {name: 'Arvore 7', labName: "Olea europaea L.7", id: 7, latitude: 38.6359753, longitude: -9.1461111, type: TreeType.HISTORICAL}),
        Object.assign(new Tree(), {name: 'Arvore 8', labName: "Olea europaea L.8", id: 8, latitude: 38.6971975, longitude: -9.4223284, type: TreeType.GARDEN}),
        Object.assign(new Tree(), {name: 'Arvore 9', labName: "Olea europaea L.9", id: 9, latitude: 38.6357953, longitude: -9.1853222, type: TreeType.GARDEN})
      ],
      code: 0
    })

    //console.log(JSON.stringify(result));

    return of(result);
  }

  // adopt tree
  adoptTree(treeId: number, user: User, dummy: Tree): Observable<TreeResult> {
    const body = {
      treeID: treeId,
      userID: user.userId,      
      token: user.token
    }

    const url = this.API_SERVICE + '/keepTree';

    return this.http.post(url, body)
      .pipe(        
        tap(value => console.log(value)),

        map(value => value as TreeResult)
      )


    //remove tree from parameters
    const dummy2 = Object.assign(new Tree(), {
      ...dummy
    })
    dummy2.type = TreeType.ADOPTED;
    dummy2.ownerId = user.userId;
    dummy2.ownerName = user.publicName;

    return of( Object.assign(new TreeResult(), {code: 0, data: dummy2}))
  }

}