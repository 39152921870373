<mat-toolbar>
  <mat-toolbar-row class="main-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <span (click)="menuToggle.emit()"><i class="icon-lg fas fa-bars"></i></span>

    <span class="spacer"></span>

    <span class="font-xmd" *ngIf="!authService.user$.value">{{ 'SHARED.TOPMENU.WELCOME' | translate }}</span>
    <span class="font-xmd" *ngIf="!!authService.user$.value">{{ 'SHARED.TOPMENU.WELCOME.USER' | translate: {value: authService.user$.value.name} }}</span>

  </mat-toolbar-row>
</mat-toolbar>

<!-- progress -->
<app-loading></app-loading>