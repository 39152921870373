<div class="map">
  <agm-map #map
    [latitude]="latitude" 
    [longitude]="longitude" 
    [minZoom]="17" 
    [maxZoom]="20" 
    [zoom]="zoom" 
    [disableDefaultUI]="true" 
    [clickableIcons]="false" 
    style="display:block;" 
    (idle)="idleMap$.next()"
    (boundsChange)="boundsChange($event)"
    (zoomChange)="this.zoom = $event">
    <!-- (boundsChange)="dragMap$.next($event)" -->
    <!-- trees markers -->
    <ng-container *ngIf="ready">
      <agm-marker-cluster
          imagePath="assets/images/trees/icon_green_group_m"
          [imageSizes]="[50,50,50,50,50,50]"
          [imageExtension]="'svg'"
          [enableRetinaIcons]="true"
          clusterClass="map-cluster"
          [minimumClusterSize]="minClusterSize"
          [maxZoom]="19">

        <!-- markers -->  
        <agm-overlay *ngFor="let tree of markers"
          [latitude]="tree.latitude"
          [longitude]="tree.longitude">      
          
          <ng-content *ngTemplateOutlet="treeMarker; context: { tree: tree }"></ng-content>
          <!-- info -->
          <agm-info-window>
            <ng-content *ngTemplateOutlet="treeInfo; context: { tree: tree }"></ng-content>
          </agm-info-window>
        </agm-overlay> 

      </agm-marker-cluster>

    </ng-container>

  </agm-map>
</div>

<!-- Tree templates -->
<ng-template #treeMarker let-tree="tree">
  <div>
    <img class="tree-marker" [src]="treeIcons.get(tree.type)">
  </div>
</ng-template>

<!-- Info window -->
<ng-template #treeInfo let-tree="tree">
  <div class="tree-info">
    <div class="item-info">
      <label class="green">{{ 'SHARED.MAP.NAME' | translate }}</label>
      <span class="font-lg">{{ tree.name }}</span>
    </div>

    <div class="item-info">
      <label class="green">{{ 'SHARED.MAP.LABNAME' | translate }}</label>
      <i style="font-size: 12px;">{{ tree.labName }}</i>
    </div>

    <!-- tree actions (depending on Tree availability) -->
    <ng-container [ngSwitch]="tree.type" *ngIf="!tree.showSuccess; else successMsg">

      <ng-container *ngSwitchCase="treeType.AVAILABLE">        
        <button class="button main-button more-info-available" [ngClass]="{'unauthorized': !authService.user$.value}" (click)="adoptTree.emit(tree)">{{ 'SHARED.MAP.ADOPT.BTN' | translate }}</button>

        <button class="button secondary-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>

        <span fxLayout="column" class="auth-container" *ngIf="!authService.user$.value">

          <span>{{ 'SHARED.MAP.LOGIN.HINT' | translate }}</span>
          <span class="auth-links">
            <span>{{ 'SHARED.MAP.LOGIN.HINT1' | translate }}</span>
            <a mat-button [routerLink]="'/login'" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.LOGIN.LINK' | translate }}</a>
  
            <span>{{ 'SHARED.MAP.LOGIN.HINT2' | translate }}</span>
            <a mat-button [routerLink]="'/sign-up'" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.SIGN.LINK' | translate }}</a>
          </span>

        </span>
       

      </ng-container>
      
      <ng-container *ngSwitchCase="treeType.ADOPTED">
        <button class="button main-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>

        <!-- parent name -->
        <div class="item-info">
          <label class="dark-gray">{{ 'SHARED.MAP.OWNER' | translate }}</label>
          <span>{{ tree.ownerName }}</span> 
        </div>

      </ng-container>

      <ng-container *ngSwitchDefault>
        <button class="button main-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>        
      </ng-container>

    </ng-container>

  </div>


</ng-template>

<ng-template #successMsg>
  <div class="success-msg green">{{ 'SHARED.MAP.SUCCESS.MSG' | translate }}</div>
</ng-template>