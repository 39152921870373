<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">

    <!-- title -->
    <div class="font-lg dark-blue">{{ 'THERMS.TITLE' | translate }}</div>

    <div class="therms-container">
      1. O Município de Odivelas, com sede na Rua Guilherme Gomes Fernandes, n.º 72, 2675-372 Odivelas, é a entidade responsável pela recolha dos dados pessoais, os quais são recolhidos no estrito âmbito das atribuições e competências legais dos municípios.
      <br><br>
      2. As pessoas singulares (titulares dos dados) poderão contactar, por escrito, a Encarregada de Proteção de Dados (EPD) do Município de Odivelas/ Dra. Paula Ganchinho, sobre todas as questões relacionadas com o tratamento dos seus dados e o exercício dos seus direitos, via correio eletrónico, através do endereço protecaodedados@cm-odivelas.pt, ou via correio postal, para a Avenida Amália Rodrigues, n.º 27, 6.º piso, Urbanização da Ribeirada, 2675-432 Odivelas.
      <br><br>
      3. Os dados pessoais são recolhidos pelo Município de Odivelas ao abrigo da App “Adote uma Árvore”, nos termos da competência estabelecida na alínea u) do n.º 1 do artigo 33.º da Lei n.º 75/2013, de 12 de setembro.
      <br><br>
      4. As pessoas singulares, titulares dos dados, têm direito:
      <br><br>
        a) A solicitar ao Município de Odivelas o acesso, a retificação, o apagamento, a limitação ou a oposição do tratamento aos/dos seus dados pessoais, bem como a portabilidade desses dados;
      <br><br>
        b) A apresentar reclamação à autoridade nacional de controlo – Comissão Nacional de Proteção de Dados.
      <br><br>
      5. Informa-se ainda que:
      <br><br>
        a) Os dados pessoais podem ser fornecidos a autoridades judiciais ou administrativas, para cumprimento de uma obrigação jurídica a que o Município de Odivelas esteja sujeito;
      <br><br>
        b) Caso a comunicação/disponibilização de dados pessoais constitua uma obrigação legal ou contratual, o titular dos dados está obrigado a fornecê-los, sendo que, se declinar/recusar essa comunicação/disponibilização, o pedido/requerimento poderá ser objeto de rejeição liminar.
      <br><br>
      6. Os dados pessoais referidos serão recolhidos, registados e organizados pelo Município de Odivelas para efeitos de operacionalização da App, nomeadamente para organização de ações e contatos no âmbito do desenvolvimento da App.
      <br><br>
      7. O Município do Odivelas conservará os dados pelo tempo que se revelar necessário para a prossecução da finalidade da App, procedendo à sua eliminação aquando da sua conclusão.
      <br><br>
      8. O Município de Odivelas não toma decisões automatizadas, ou seja, não utiliza qualquer forma de tratamento automatizado de dados pessoais. 
      <br><br>
      9. Para mais informações pode ser consultada a Política de Proteção e Privacidade de Dados, disponível no sítio institucional da Câmara Municipal de Odivelas (<a href="https://www.cm-odivelas.pt/cmodivelas/uploads/document/file/7056/politica_de_protecao_e_privacidade_de_dados_do_municipio_de_odivelas.pdf" target="_blank">consulte aqui</a>).
    </div>

    <!-- back button -->
    <a class="close-btn dark-gray" mat-button [routerLink]="'/dashboard'">
      <span><i class="fas fa-chevron-left"></i>&nbsp;{{ 'THERMS.GO.BACK.BTN' | translate }}</span>
    </a>
    
  </mat-drawer-content>
</mat-drawer-container>