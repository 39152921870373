import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() user: User;

  activatedRoute: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {    
    
    // just for color button
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )    
      .subscribe((value: NavigationEnd) => {
        const availableRoutes = ['/user/my-trees', '/map-view', '/news'];
        const activatedUrl = value.url;

        let url = '';
        
        availableRoutes.forEach(value => {
          if(activatedUrl.includes(value)) {
            url = value;
          }
        })

        this.activatedRoute = !!url.length
          ? url
          : '';        
      })
    
  }

  navigate(route: string, secondRoute: string = '') {
    const fullRoute = !!secondRoute.length 
      ? [route, secondRoute]
      : [ route ]
    this.router.navigate([{ outlets: { primary: fullRoute, treedialog: null } }])

  }

}
