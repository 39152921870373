<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">
    
    <!-- Map -->
    <div class="map-container">
      <!--<app-map (adoptTree)="adopt($event)"></app-map>-->
      <app-google-tree-map [triggerTreeId]="triggerTreeId" (adoptTree)="adopt($event)"></app-google-tree-map>
    </div>

  </mat-drawer-content>
</mat-drawer-container>