<div class="sign-container">
  <!-- logo -->
  <div class="logo">
    <img src="assets/images/logo_extended.svg">
  </div>

  <div class="font-lg dark-blue">{{ 'SIGN.TITLE' | translate }}</div>

  <div fxLayout="row" fxLayoutAlign="center center" class="white-background menu-separator">
    <img src="assets/images/parts/bottom-shadow.png" />
  </div>

  <form [formGroup]="form" (submit)="signUp()">
    <div class="inner-container">
      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.NAME' | translate }}" formControlName="name">
        <span class="dark-blue" align="center">{{ 'SIGN.NAME.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.PUBLIC.NAME' | translate }}" formControlName="publicName">
        <span class="dark-blue" align="center">{{ 'SIGN.PUBLIC.NAME.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.EMAIL' | translate }}" formControlName="email">
        <span class="dark-blue" align="center">{{ 'SIGN.EMAIL.HINT' | translate }}</span>
      </label>
      
      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.ZONE' | translate }}" formControlName="zone">
        <span class="dark-blue" align="center">{{ 'SIGN.ZONE.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.ZIPCODE' | translate }}" formControlName="zipcode" pattern="^[0-9]{4}(?:-[0-9]{3})?$"> 
        <span class="dark-blue" align="center">{{ 'SIGN.ZIPCODE.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="text" placeholder="{{ 'SIGN.LOCAL' | translate }}" formControlName="local">
        <span class="dark-blue" align="center">{{ 'SIGN.LOCAL.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="password" placeholder="{{ 'SIGN.PASSWORD' | translate }}" formControlName="password">
        <span class="dark-blue" align="center">{{ 'SIGN.PASSWORD.HINT' | translate }}</span>
      </label>

      <div class="custom-check">
        <input type="checkbox" id="therms" name="therms" formControlName="therms">
        <label for="therms">{{ 'SIGN.THERMS' | translate }}
          <a mat-link-button [routerLink]="'/therms'">{{ 'SIGN.THERMS.LINK' | translate }}</a>
        </label>
      </div>

      <span class="spacer"></span>

      <div class="errorMsg" [ngClass]="showError ? '' : 'hidden'">{{ 'SIGN.UP.ERROR.MSG' | translate }}</div>

      <button class="button main-button" type="submit" [disabled]="!form.valid">{{ 'SIGN.UP.BTN' | translate }}</button>            
    </div>
  </form>

  <a class="close-btn font-lg dark-blue" mat-button [routerLink]="'/' + returnUrl"><i class="icon-lg fas fa-times"></i></a>

</div>

<!-- progress -->
<app-loading [topScreen]="true"></app-loading>