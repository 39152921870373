import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TreeResult } from 'src/app/core/models/treeresult';
import { SystemService } from 'src/app/core/system.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', Validators.required),
  });

  returnUrl: string = 'dashboard';

  showError: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private systemService: SystemService) { }

  ngOnInit() {
    this.route.queryParamMap
      .subscribe(params => {
        // Defaults to 'dashboard' if no query param provided.
        this.returnUrl = params.get('returnUrl') || 'dashboard';
      });

    if(this.systemService.getMobileOperatingSystem() === 'Android'){
      // focus events don't bubble, must use capture phase
      document.body.addEventListener("focus", event => {
        const target:any = event.target;
        switch (target.tagName) {
            case "INPUT":
            case "TEXTAREA":
            case "SELECT":
                document.body.classList.add("keyboard");
        }

        setTimeout(() => {
          //target.scrollIntoView();
        })

      }, true); 
      document.body.addEventListener("blur", () => {
        setTimeout(() => {
          const isFocused = document.activeElement.tagName === 'INPUT';
          if(!isFocused)
            document.body.classList.remove("keyboard");
        }, 300)
      }, true); 
    }
  }

  login() {
    const { email, password } = this.form.value;

    this.showError = false;

    this.authService.login(email, password)      
      .subscribe((value: TreeResult) => {
        if(TreeResult.ok(value)){
          this.router.navigate([''])
        } else {
          this.showError = true
        }
      })
  }

}
