export class Tree {
  // mandatory
  id: number;
  name: string;
  thumbnail: string;
  latitude: number;
  longitude: number;

  // map
  type: TreeType;
  ownerId: number;
  ownerName: string;

  //details
  labName: string;
  family: string;
  origin: string;
  description: string;
  useCases: string;
  curiosities: string;
  photos: string[] = [];

  locations: TreeLocations[] = [];

  // show sucess - only used on adopt map
  showSuccess: boolean;
}

// tree locations
export class TreeLocations {
  id: number;
  name: string;
  thumbnail: string;
  latitude: number;
  longitude: number;
}

export enum TreeType {
  AVAILABLE = 0,
  ADOPTED = 1,
  HISTORICAL = 2,
  GARDEN = 3
}