import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Tree } from '../core/models/tree';

@Component({
  selector: 'app-tree-detail',
  templateUrl: './tree-detail.component.html',
  styleUrls: ['./tree-detail.component.scss']
})
export class TreeDetailComponent implements OnInit {

  tree: Tree;

  photosChanged$ = new Subject<string>();
  indexesPhotos:number[] = [0, 1];

  locationsChanged$ = new Subject<string>();
  indexesLocationPhotos:number[] = [0, 1];
  
  returnUrl: string = 'dashboard';

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit() {
    // resolver data
    this.route.data
      .subscribe((value: {tree: Tree}) => {
        this.tree = value.tree;

        console.log(this.tree)
        
        this.setupPhotosCarousel();

        this.setupLocationPhotosCarousel();
      });

    this.route.queryParamMap
      .subscribe(params => {
        // Defaults to 'dashboard' if no query param provided.
        this.returnUrl = params.get('returnUrl') || 'dashboard';
      });

    // carousel photos handler
    this.photosChanged$
      .subscribe((value: string) => {
        if(value === 'right') {
          const [leftPic, rightPic] = [this.indexesPhotos[0], this.indexesPhotos[1]];

          this.indexesPhotos[0] = (leftPic === this.tree.photos.length - 1) ? 0 : (leftPic + 1);
          this.indexesPhotos[1] = (this.tree.photos.length - 1 === rightPic) ? 0 : (rightPic + 1);
        }
        if(value === 'left') {
          const [leftPic, rightPic] = [this.indexesPhotos[0], this.indexesPhotos[1]];

          this.indexesPhotos[0] = leftPic === 0 ? (this.tree.photos.length - 1) : (leftPic - 1);
          this.indexesPhotos[1] = (this.indexesPhotos[0] === (this.tree.photos.length - 1)) ? 0 : (this.indexesPhotos[0] + 1);
        }
      })

    // carousel photos handler
    this.locationsChanged$
      .subscribe((value: string) => {
        if(value === 'right') {
          const [leftPic, rightPic] = [this.indexesLocationPhotos[0], this.indexesLocationPhotos[1]];

          this.indexesLocationPhotos[0] = (leftPic === this.tree.locations.length - 1) ? 0 : (leftPic + 1);
          this.indexesLocationPhotos[1] = (this.tree.locations.length - 1 === rightPic) ? 0 : (rightPic + 1);
        }
        if(value === 'left') {
          const [leftPic, rightPic] = [this.indexesLocationPhotos[0], this.indexesLocationPhotos[1]];

          this.indexesLocationPhotos[0] = leftPic === 0 ? (this.tree.locations.length - 1) : (leftPic - 1);
          this.indexesLocationPhotos[1] = (this.indexesLocationPhotos[0] === (this.tree.locations.length - 1)) ? 0 : (this.indexesLocationPhotos[0] + 1);
        }
      })

  }

  private setupPhotosCarousel() {
    // photos
    if(this.tree.photos.length <= 1) { return;}

    this.indexesPhotos = [0, 1];

  }

  private setupLocationPhotosCarousel() {
    // photos
    if(this.tree.locations.length <= 1) { return;}

    this.indexesLocationPhotos = [0, 1];

  }

}
