<div class="login-container">
  <!-- logo -->
  <div class="logo">
    <img src="assets/images/logo_extended.svg">
  </div>

  <div class="font-lg dark-blue">{{ 'LOGIN.TITLE' | translate }}</div>

  <div fxLayout="row" fxLayoutAlign="center center" class="white-background menu-separator">
    <img src="assets/images/parts/bottom-shadow.png" />
  </div>

  <form [formGroup]="form" (submit)="login()">
    <div class="inner-container">
      <label class="custom-input">      
        <input type="text" placeholder="{{ 'LOGIN.EMAIL' | translate }}" formControlName="email">
        <span class="dark-blue" align="center">{{ 'LOGIN.EMAIL.HINT' | translate }}</span>
      </label>

      <label class="custom-input">      
        <input type="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" formControlName="password">
        <span class="dark-blue" align="center">{{ 'LOGIN.PASSWORD.HINT' | translate }}</span>
      </label>

      <div class="errorMsg" [ngClass]="showError ? '' : 'hidden'">{{ 'LOGIN.ERROR.MSG' | translate }}</div>

      <button class="button main-button" type="submit" [disabled]="!form.valid">{{ 'LOGIN.LOGIN.BTN' | translate }}</button>
      
      <div class="new-account">                 
        <span>{{ 'LOGIN.HINT' | translate }}</span>&nbsp;
        <a [routerLink]="'/sign-up'">{{ 'LOGIN.LINK' | translate }}</a>
      </div>

    </div>
  </form>

  <a class="close-btn font-lg dark-blue" mat-button [routerLink]="'/' + returnUrl"><i class="icon-lg fas fa-times"></i></a>

</div>

<!-- progress -->
<app-loading [topScreen]="true"></app-loading>