import { MapsAPILoader } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiService } from 'src/app/core/api.service';
import { Tree, TreeType } from 'src/app/core/models/tree';
import { TreeResult } from 'src/app/core/models/treeresult';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {

  icons: any  = {};
  selectionIcons: any  = {};

  triggerTreeId: number;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthService,
    private mapsAPILoader: MapsAPILoader ) { }

  ngOnInit() {  

    this.route.queryParamMap
      .subscribe(params => {
        // get lat lng from tree-detail navigation
        const treeId = params.get('treeId')
        this.triggerTreeId = !!treeId 
          ? +treeId 
          : undefined ;
      });

    this.mapsAPILoader.load()
      .then(() => {
        this.setupIcons();    
      })    
  }

  adopt(event: { tree: Tree, marker: any }) {
    this.apiService.adoptTree(event.tree.id, this.authService.user$.value, event.tree)
      .pipe(
        filter(value => TreeResult.ok(value))
      )
      .subscribe((value: TreeResult) => {
        const updatedTree = value.data as Tree;

        event.tree.type = updatedTree.type;
        event.tree.ownerId = updatedTree.ownerId;
        event.tree.ownerName = updatedTree.ownerName;
        event.tree.showSuccess = true;

        // update icon
        event.marker.setIcon(null);
        const newIcon = this.getSelectedTreeIcon(event.tree)
        event.marker.setIcon(newIcon);
      })    
  }

  // for map changing icon based on tree type
  setupIcons() {
    // default icons
    // we dont need here

    // selected tree icons
    this.selectionIcons = {
      available: {
        icon: {
          url: "assets/images/trees/icon_blue_map_selected.svg",
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      adopted: {
        icon: {
          url: 'assets/images/trees/icon_green_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      historical: {       
        icon: {
          url: 'assets/images/trees/icon_brown_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      },
      garden: {
        icon: {
          url: 'assets/images/trees/icon_darkgreen_map_selected.svg',
          size: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13, -54),
          scaledSize: new google.maps.Size(50, 50)
        }
      }
    }
  }

  private getSelectedTreeIcon(tree: Tree) {

    switch (tree.type) {
      case TreeType.AVAILABLE: 
        return this.selectionIcons.available.icon;

      case TreeType.ADOPTED: 
        return this.selectionIcons.adopted.icon;

      case TreeType.HISTORICAL: 
        return this.selectionIcons.historical.icon;

      case TreeType.GARDEN: 
        return this.selectionIcons.garden.icon;    
    }  
  }

}
