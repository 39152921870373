<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">

    <!-- title -->
    <div class="font-lg dark-blue">{{ 'CONTACTS.TITLE' | translate }}</div>

       
    
    <!-- back button -->
    <a class="close-btn dark-gray" mat-button [routerLink]="'/dashboard'">
      <span><i class="fas fa-chevron-left"></i>&nbsp;{{ 'CONTACTS.GO.BACK.BTN' | translate }}</span>
    </a>
    
  </mat-drawer-content>
</mat-drawer-container>