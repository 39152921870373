<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">

    <!-- title -->
    <div class="font-lg dark-blue">{{ 'PROFILE.TITLE' | translate }}</div>

    <!-- form -->
    <form [formGroup]="form" (submit)="save()">
      <div class="inner-container">
        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.NAME' | translate }}" formControlName="name" [readonly]="true">
          <span class="dark-blue" align="center">{{ 'PROFILE.NAME.HINT' | translate }}</span>
        </label>

        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.PUBLIC.NAME' | translate }}" formControlName="publicName">
          <span class="dark-blue" align="center">{{ 'PROFILE.PUBLIC.NAME.HINT' | translate }}</span>
        </label>

        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.EMAIL' | translate }}" formControlName="email" [readonly]="true">
          <span class="dark-blue" align="center">{{ 'PROFILE.EMAIL.HINT' | translate }}</span>
        </label>
        
        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.ZONE' | translate }}" formControlName="zone" [readonly]="true">
          <span class="dark-blue" align="center">{{ 'PROFILE.ZONE.HINT' | translate }}</span>
        </label>

        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.ZIPCODE' | translate }}" formControlName="zipcode" [readonly]="true">
          <span class="dark-blue" align="center">{{ 'PROFILE.ZIPCODE.HINT' | translate }}</span>
        </label>

        <label class="custom-input">      
          <input type="text" placeholder="{{ 'PROFILE.LOCAL' | translate }}" formControlName="local" [readonly]="true">
          <span class="dark-blue" align="center">{{ 'PROFILE.LOCAL.HINT' | translate }}</span>
        </label>

        <span class="spacer"></span>

        <button class="button main-button" type="submit" [disabled]="!form.valid">{{ 'PROFILE.SAVE.BTN' | translate }}</button>
      </div>
    </form>

    <!-- back button -->
    <a class="close-btn dark-gray" mat-button [routerLink]="'/dashboard'">
      <span><i class="fas fa-chevron-left"></i>&nbsp;{{ 'PROFILE.GO.BACK.BTN' | translate }}</span>
    </a>
    
  </mat-drawer-content>
</mat-drawer-container>