import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AgmMap, LatLngBounds, MapsAPILoader } from '@agm/core';
import { ApiService } from 'src/app/core/api.service';
import { Observable, Subject } from 'rxjs';
import { TreeResult } from 'src/app/core/models/treeresult';
import { Tree, TreeType } from 'src/app/core/models/tree';
import { AuthService } from 'src/app/auth/auth.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() adoptTree = new EventEmitter<Tree>();

  // trees
  trees: Tree[] = [];
  markers: Tree[] = [];

  // map location
  latitude: number = 38.7954;
  longitude: number = -9.1852;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild(AgmMap) AgmMap: AgmMap;

  dragMap$ = new Subject<LatLngBounds>();
  idleMap$ = new Subject<void>();

  readonly treeIcons = new Map<number, string>()
    .set(TreeType.AVAILABLE, 'assets/images/trees/icon_blue_map.svg')
    .set(TreeType.ADOPTED, 'assets/images/trees/icon_green_map.svg')
    .set(TreeType.HISTORICAL, 'assets/images/trees/icon_brown_map.svg')
    .set(TreeType.GARDEN, 'assets/images/trees/icon_darkgreen_map.svg')

  treeType = TreeType;

  ready: boolean;

  minClusterSize = 5;
  clulster_max_zoom = 4;

  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    public authService: AuthService,
    private apiService: ApiService,
    private mapsAPILoader: MapsAPILoader) { }

  ngOnInit() {
    // current location
    //this.loadCurrentLocation();    
    this.zoom = 18;

    // trees collection
    this.apiService.loadMapTrees()
      .subscribe((value: TreeResult) => {
        if(TreeResult.ok(value)) {
          const trees = value.data as Tree[];
          this.trees = trees;


          this.ready = true;
        }
      })

    // delaying bound Changes
    this.dragMap$
      .pipe(
        debounceTime(500)
      )
      .subscribe((value: LatLngBounds) => this.updateMarkers(value)); 

    this.idleMap$
      .pipe(
        debounceTime(500)
      )
      .subscribe(_ => this.idleMap()); 
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.dragMap$.complete();
  }

  loadCurrentLocation(){
    this.mapsAPILoader.load()
      .then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
      });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        this.zoom = 18;

        this.ready = true;
        // Dev only
        //this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  // get well detailed address - dev only
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }    
    });
  }
    
  updateMarkers(newBounds: LatLngBounds) {
  
    if(!this.trees.length || !newBounds) {return; }
    const markers = this.trees
      .filter((tree: Tree) => {
        const marker =  new google.maps.LatLng(tree.latitude, tree.longitude);
        return newBounds.contains(marker)
      })
      console.log(`updating trees, zoom: ${this.zoom}, markers: ${markers.length}` )

      this.markers = markers
      
      this.changeDetector.detectChanges();
      
      
  }

  latlngBounds: LatLngBounds;
  boundsChange(newBounds: LatLngBounds) {
    this.latlngBounds = newBounds;
  }

  idleMap(){
    console.log("Bounds:",this.latlngBounds);

    this.updateMarkers(this.latlngBounds)
    
  }

}

