import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateParser, TranslateDefaultParser } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SystemService } from './core/system.service';
import { AuthService } from './auth/auth.service';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { ContactsComponent } from './contacts/contacts.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserModule } from './user/user.module';
import { MapViewModule } from './map-view/map-view.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TreeDetailComponent } from './tree-detail/tree-detail.component';
import { AgmCoreModule } from '@agm/core';
import { CryptService } from './core/crypt.service';
import { HeaderInterceptor } from './core/header.interceptor';
import { ThermsComponent } from './therms/therms.component';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { 
      prefix: 'assets/i18n/app-', 
      suffix: '.json' 
    }
  ]);
}

// start app system
export function start(systemService: SystemService, authService: AuthService) {
  return () => systemService.start(authService);
}

@NgModule({
  declarations: [
    AppComponent,
    ContactsComponent,
    PageNotFoundComponent,
    TreeDetailComponent,
    ThermsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,  
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateDefaultParser 
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCyLSpm5HWUhkaVePlssoSl42A7ilfMsN4'
    }),
    AuthModule,
    DashboardModule,
    AppRoutingModule,
    UserModule,
    MapViewModule,
    SharedModule
  ],
  providers: [
    { 
      provide: APP_INITIALIZER, 
      useFactory: start, 
      deps: [SystemService, AuthService], 
      multi: true 
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true 
    },
    CryptService
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
