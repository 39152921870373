import { Component, Input, OnInit } from '@angular/core';
import { SystemService } from 'src/app/core/system.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() topScreen: boolean;
  
  constructor(
    public systemService: SystemService) { }

  ngOnInit() {
  }

}
