import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SystemService } from 'src/app/core/system.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Output() menuToggle: EventEmitter<void> = new EventEmitter();
  
  constructor(
    public systemService: SystemService,
    public authService: AuthService) { }

  ngOnInit() {
  }

}
