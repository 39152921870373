import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { SnapshotComponent } from './snapshot/snapshot.component';
import { MenuComponent } from './menu/menu.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from "agm-overlays";
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { GoogleTreeMapComponent } from './google-tree-map/google-tree-map.component';
import { LoadingComponent } from './loading/loading.component';
 

@NgModule({
  declarations: [FooterComponent, SnapshotComponent, MenuComponent, NewsletterComponent, TopMenuComponent, MapComponent, GoogleTreeMapComponent, LoadingComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AgmCoreModule,
    AgmOverlays,
    AgmMarkerClustererModule,
    RouterModule
  ],
  exports: [
    MaterialModule,    
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    FooterComponent,
    SnapshotComponent,
    MenuComponent,
    NewsletterComponent,
    TopMenuComponent,
    AgmOverlays,
    AgmMarkerClustererModule,
    MapComponent,
    GoogleTreeMapComponent,
    LoadingComponent
  ]
})
export class SharedModule { }
