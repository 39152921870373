import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '../core/api.service';
import { CacheService } from '../core/cache.service';
import { TreeResult } from '../core/models/treeresult';
import { User } from '../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<User>(undefined);

  constructor(
    private cacheService: CacheService,
    private apiService: ApiService) { }

  login(email: string, password: string): Observable<TreeResult> {
    return this.apiService.login(email, password)
      .pipe(      
        tap((value: TreeResult) => {
        
          if(TreeResult.ok(value)) {
            const user = value.data as User;

            // set cache
            this.cacheService.setItem('USER', user);

            // update internal user
            this.user$.next(user);
          }        
        })
      );
  }

  loginByToken(token: string): Observable<TreeResult> {
    return this.apiService.loginByToken(token)
      .pipe(      
        tap((value: TreeResult) => {
        
          if(TreeResult.ok(value)) {
            const user = value.data as User;

            // set cache
            this.cacheService.setItem('USER', user);

            // update internal user
            this.user$.next(user);
          }        
        })
      );
  }

  signUp(user: User):Observable<TreeResult> {
    return this.apiService.signUp(user)
      .pipe(      
        tap((value: TreeResult) => {
        
          if(TreeResult.ok(value)) {
            const user = value.data as User;

            // set cache
            this.cacheService.setItem('USER', user);

            // update internal user
            this.user$.next(user);
          }        
        })
      );
  }

  logout(): void {
    // set cache
    this.cacheService.setItem('USER', undefined);

    this.user$.next(undefined);
  }
}
