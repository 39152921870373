import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-therms',
  templateUrl: './therms.component.html',
  styleUrls: ['./therms.component.scss']
})
export class ThermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
