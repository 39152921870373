export class User {
  userId: number;
  name: string;
  publicName: string;
  email: string;
  zone: string;
  zipcode: string;
  local: string;
  password: string;

  token: string;
}