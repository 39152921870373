import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Tree } from 'src/app/core/models/tree';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-my-trees',
  templateUrl: './my-trees.component.html',
  styleUrls: ['./my-trees.component.scss']
})
export class MyTreesComponent implements OnInit {

  trees: Tree[] = [];

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {    
    // resolver data
    this.route.data
      .subscribe((value: {trees: Tree[]}) => {
        this.trees = value.trees;

        console.log(this.trees)
      });
    
    // user subscription
    this.authService.user$  
      .subscribe((value: User) => {
        // kickout when logout
        if(!value){
          this.router.navigate(['']);
        }
      });

  }

}
