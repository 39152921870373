import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeResult } from 'src/app/core/models/treeresult';
import { User } from 'src/app/core/models/user';
import { SystemService } from 'src/app/core/system.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    publicName: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.required]),
    zone: new FormControl('', Validators.required),
    zipcode: new FormControl('', Validators.required),
    local: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    therms: new FormControl(false, Validators.requiredTrue)
  });

  returnUrl: string = 'dashboard';

  showError: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private systemService: SystemService) { }

  ngOnInit() {
    this.route.queryParamMap
      .subscribe(params => {
        // Defaults to 'dashboard' if no query param provided.
        this.returnUrl = params.get('returnUrl') || 'dashboard';
      });

      if(this.systemService.getMobileOperatingSystem() === 'Android'){
        // focus events don't bubble, must use capture phase
        document.body.addEventListener("focus", event => {
          const target:any = event.target;
          switch (target.tagName) {
              case "INPUT":
              case "TEXTAREA":
              case "SELECT":
                  document.body.classList.add("keyboard");
          }

          setTimeout(() => {
            //target.scrollIntoView();
          })

        }, true); 
        
        document.body.addEventListener("blur", () => {
          setTimeout(() => {
            const isFocused = document.activeElement.tagName === 'INPUT';
            if(!isFocused)
              document.body.classList.remove("keyboard");
          }, 300)
            
        }, true); 
      }
  }

  signUp(){
    const newUser: User = {
      ...this.form.value
    }

    this.showError = false;

    this.authService.signUp(newUser)
      .subscribe((value: TreeResult) => {
        if(TreeResult.ok(value)){
          this.router.navigate([''])
        } else {
          this.showError = true;
        }   
      })
  }
  
}
