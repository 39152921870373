import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { SystemService } from './core/system.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  footerAvailable: boolean;

  constructor(
    private systemService: SystemService,
    private translateService: TranslateService,
    public authService: AuthService,
    private router: Router) {}

  ngOnInit() {
    
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('pt');

    // setup language to use
    this.translateService.use(this.systemService.language$.value);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )    
      .subscribe((value: NavigationEnd) => {
        const footerUnvailableAt = ['/login', '/sign-up'];
        const activatedUrl = value.url;

        this.footerAvailable = !footerUnvailableAt.includes(activatedUrl);
        console.log(activatedUrl)
        
      })
    
  }

}
