<div class="map">
  
  <div id="map"></div>

  <ng-container #infoWindow></ng-container>

</div>

<!-- loading -->
<div class="loading-image" *ngIf="!ready">
  <img src="assets/images/app_cmo_icon_app.svg">
</div>


<!-- Info window -->
<ng-template #treeInfo let-tree="tree">
  <div class="tree-info" [ngStyle]="{'top': top + 'px'}">
    <div class="item-info">
      <label class="green">{{ 'SHARED.MAP.NAME' | translate }}</label>
      <span class="font-lg">{{ tree.name }}</span>
    </div>

    <div class="item-info">
      <label class="green">{{ 'SHARED.MAP.LABNAME' | translate }}</label>
      <i style="font-size: 12px;">{{ tree.labName }}</i>
    </div>

    <!-- tree actions (depending on Tree availability) -->
    <ng-container [ngSwitch]="tree.type" *ngIf="!tree.showSuccess; else successMsg">

      <ng-container *ngSwitchCase="treeType.AVAILABLE">        
        <button class="button main-button more-info-available" [ngClass]="{'unauthorized': !authService.user$.value}" (click)="adoptTree.emit(markers.get(tree.id))">{{ 'SHARED.MAP.ADOPT.BTN' | translate }}</button>

        <!--<button class="button secondary-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>-->
        <button class="button secondary-button" [routerLink]="['/', { outlets: { treedialog: ['tree', tree.id] } }]">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>

        <span fxLayout="column" class="auth-container" *ngIf="!authService.user$.value">

          <span>{{ 'SHARED.MAP.LOGIN.HINT' | translate }}</span>
          <span class="auth-links">
            <span>{{ 'SHARED.MAP.LOGIN.HINT1' | translate }}</span>
            <a mat-button [routerLink]="'/login'" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.LOGIN.LINK' | translate }}</a>
  
            <span>{{ 'SHARED.MAP.LOGIN.HINT2' | translate }}</span>
            <a mat-button [routerLink]="'/sign-up'" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.SIGN.LINK' | translate }}</a>
          </span>

        </span>
       

      </ng-container>
      
      <ng-container *ngSwitchCase="treeType.ADOPTED">
        <!--<button class="button main-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>-->
        <button class="button main-button" [routerLink]="['/', { outlets: { treedialog: ['tree', tree.id] } }]">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>
        

        <!-- parent name -->
        <div class="item-info">
          <label class="dark-gray">{{ 'SHARED.MAP.OWNER' | translate }}</label>
          <span>{{ tree.ownerName }}</span> 
        </div>

      </ng-container>

      <ng-container *ngSwitchDefault>
        <!--<button class="button main-button" [routerLink]="['/tree', tree.id]" [queryParams]="{ returnUrl: 'map-view' }">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>-->
        <button class="button main-button" [routerLink]="['/', { outlets: { treedialog: ['tree', tree.id] } }]">{{ 'SHARED.MAP.MORE.INFO.BTN' | translate }}</button>  
      </ng-container>

    </ng-container>

    <div class="close-btn dark-gray" (click)="disbandInfoWindow()">
      <span><i class="icon-lg fas fa-times"></i></span>
    </div>

  </div>
</ng-template>

<ng-template #successMsg>
  <div class="success-msg green">{{ 'SHARED.MAP.SUCCESS.MSG' | translate }}</div>
</ng-template>

