import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsComponent } from './contacts/contacts.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ThermsComponent } from './therms/therms.component';
import { TreeDetailResolverService } from './tree-detail/tree-detail-resolver.service';
import { TreeDetailComponent } from './tree-detail/tree-detail.component';


const routes: Routes = [
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'map-view',
    loadChildren: () => import('./map-view/map-view.module').then(m => m.MapViewModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'tree/:id',
    component: TreeDetailComponent,
    outlet: 'treedialog',
    resolve: {
      tree: TreeDetailResolverService
    }
  },
  {
    path: 'contacts',
    component: ContactsComponent
  },
  {
    path: 'therms',
    component: ThermsComponent
  },
  { 
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },  
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404',
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
