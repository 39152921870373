import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/core/models/user';
import { SystemService } from 'src/app/core/system.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form = new FormGroup({
    name: new FormControl(''),
    publicName: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.required]),
    zone: new FormControl('', Validators.required),
    zipcode: new FormControl('', Validators.required),
    local: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private systemService: SystemService) { }

  ngOnInit() {    
    // user subscription
    this.authService.user$      
      .subscribe((value: User) => {
        // kickout when logout
        if(!value){
          this.router.navigate(['']);
        }

        // update form data
        if(value){
          this.form.patchValue({...value});
          console.log(this.form.value)
        }        
      });

    if(this.systemService.getMobileOperatingSystem() === 'Android'){
      // focus events don't bubble, must use capture phase
      document.body.addEventListener("focus", event => {
        const target:any = event.target;
        switch (target.tagName) {
            case "INPUT":
            case "TEXTAREA":
            case "SELECT":
                document.body.classList.add("keyboard");
        }

        setTimeout(() => {
          //target.scrollIntoView();
        })

      }, true); 
      document.body.addEventListener("blur", () => {
        setTimeout(() => {
            const isFocused = document.activeElement.tagName === 'INPUT';
            if(!isFocused)
              document.body.classList.remove("keyboard");
          }, 300)
      }, true); 
    }
  }

  save() {

  }

}
