<!-- main toolbar -->
<app-top-menu (menuToggle)="drawer.toggle()"></app-top-menu>

<mat-drawer-container [hasBackdrop]="false">
  <!-- menu -->
  <mat-drawer #drawer mode="over" position="end">
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content class="main-container">
   
    <!-- news content -->
    <div class="tree-detail">

      <div class="photos-carousel" fxLayout="row" fxLayoutAlign="center center" *ngIf="tree.photos.length > 1">      
        <span (click)="photosChanged$.next('left')"><i class="icon-lg dark-gray fas fa-chevron-left"></i></span>

        <!-- pics -->
        <div>
          <div class="img-wrapper">
            <img [src]="tree.photos[indexesPhotos[0]]" >
          </div>
          <div class="img-wrapper">
            <img [src]="tree.photos[indexesPhotos[1]]" >
          </div>
        </div>

        <span (click)="photosChanged$.next('right')"><i class="icon-lg dark-gray fas fa-chevron-right" ></i></span>
      </div>      
      <img [src]="tree.photos[0]" width="40%" *ngIf="tree.photos.length === 1">

      <!-- wiki -->
      <div class="tree-info">
      
        <div class="item-info">
          <label class="green">{{ 'TREE.DETAIL.FIELD.NAME' | translate }}</label>
          <span>{{ tree.name }}</span>
        </div>
        
        <div fxLayout="row">
          <div class="item-info" fxFlex="50">
            <label class="green">{{ 'TREE.DETAIL.FIELD.LABNAME' | translate }}</label>
            <i style="font-size: 12px;">{{ tree.labName }}</i>
          </div>
          <div class="item-info" fxFlex="50">
            <label class="green">{{ 'TREE.DETAIL.FIELD.FAMILY' | translate }}</label>
            <i style="font-size: 12px;">{{ tree.family }}</i>
          </div>
        </div>

        <div class="item-info">
          <label class="green">{{ 'TREE.DETAIL.FIELD.ORIGIN' | translate }}</label>
          <span>{{ tree.origin }}</span>
        </div>

        <div class="item-info">
          <label class="green">{{ 'TREE.DETAIL.FIELD.DESCRIPTION' | translate }}</label>
          <span [innerHTML]="tree.description"></span>
        </div>

        <div class="item-info">
          <label class="green">{{ 'TREE.DETAIL.FIELD.USECASES' | translate }}</label>
          <span [innerHTML]="tree.useCases"></span>
        </div>
        
        <div class="item-info">
          <label class="green">{{ 'TREE.DETAIL.FIELD.CURIOSITIES' | translate }}</label>
          <span [innerHTML]="tree.curiosities"></span>
        </div>

      </div>


      <!-- where to find -->
      <label class="font-bold green" *ngIf="tree.locations.length > 0">{{ 'TREE.DETAIL.WHERE.TO.FIND' | translate }}</label>
      <div class="thumb-carousel" fxLayout="row" fxLayoutAlign="center center" *ngIf="tree.locations.length > 1">      
        <span (click)="locationsChanged$.next('left')"><i class="icon-lg dark-gray fas fa-chevron-left"></i></span>

        <!-- pics -->
        <div fxLayout="row">

          <div fxFlex="50" fxLayout="column" class="location-tile">
            <div class="img-wrapper" *ngIf="!!tree.locations[indexesLocationPhotos[0]].thumbnail">
              <img [src]="tree.locations[indexesLocationPhotos[0]].thumbnail" >            
            </div>
            <label class="font-md">{{ tree.locations[indexesLocationPhotos[0]].name }}</label>

            <span><span class="font-bold">{{ 'TREE.DETAIL.LATITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[0]].latitude }}</span>
            <span><span class="font-bold">{{ 'TREE.DETAIL.LONGITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[0]].longitude }}</span>
          </div>

          <div fxFlex="50" fxLayout="column" class="location-tile">
            <div class="img-wrapper" *ngIf="!!tree.locations[indexesLocationPhotos[1]].thumbnail">
              <img [src]="tree.locations[indexesLocationPhotos[1]].thumbnail">              
            </div>
            <label class="font-md">{{ tree.locations[indexesLocationPhotos[1]].name }}</label>

            <span><span class="font-bold">{{ 'TREE.DETAIL.LATITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[1]].latitude }}</span>
            <span><span class="font-bold">{{ 'TREE.DETAIL.LONGITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[1]].longitude }}</span>

          </div>

        </div>

        <span (click)="locationsChanged$.next('right')"><i class="icon-lg dark-gray fas fa-chevron-right" ></i></span>
      </div>      
      
      <div fxLayout="column" class="location-tile single-location"  *ngIf="tree.locations.length === 1">
        <img [src]="tree.locations[0].thumbnail" width="40%" *ngIf="!!tree.locations[0].thumbnail">

        <label class="font-md">{{ tree.locations[indexesLocationPhotos[0]].name }}</label>

        <span><span class="font-bold">{{ 'TREE.DETAIL.LATITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[0]].latitude }}</span>
        <span><span class="font-bold">{{ 'TREE.DETAIL.LONGITUDE' | translate }}</span>&nbsp;{{ tree.locations[indexesLocationPhotos[0]].longitude }}</span>
        
      </div>

    </div>

    
    
    <!-- back button -->
    <!--<a class="close-btn dark-gray" mat-button [routerLink]="'/' + returnUrl">-->
    <a class="close-btn dark-gray" mat-button [routerLink]="['/', { outlets: { treedialog: null } }]">
      <span><i class="fas fa-chevron-left"></i>&nbsp;{{ 'TREE.DETAIL.GO.BACK.BTN' | translate }}</span>
    </a>
    
  </mat-drawer-content>
</mat-drawer-container>
