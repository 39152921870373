import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { Newsletter } from 'src/app/core/models/newsletter';
import { TreeResult } from 'src/app/core/models/treeresult';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  newsletters: Newsletter[] = [];

  constructor(
    private apiService: ApiService,
    private router: Router) { }

  ngOnInit() {

    // load top newsletters
    this.apiService.loadNews()
      .subscribe((value: TreeResult) => {
        if(TreeResult.ok(value)) {
          this.newsletters = value.data as Newsletter[];          
        }
      });

  }

  navigate(route: string) {
    this.router.navigateByUrl(route);
  }

}
