<div class="newsletter-container" [routerLink]="['/news', newsletter.id]">

  <img [src]="newsletter.thumbnail">
      
  <div class="info-container">
    <span class="dark-gray">{{newsletter.date}}</span>
    <span>{{newsletter.title}}</span>
    <span class="dark-gray">{{ 'NEWSLETTER.SEE.MORE' | translate }}&nbsp; <i class="fas fa-chevron-right"></i></span>
  </div>

</div>