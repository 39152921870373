import { Component, Input, OnInit } from '@angular/core';
import { Newsletter } from 'src/app/core/models/newsletter';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  @Input() newsletter: Newsletter;

  constructor() { }

  ngOnInit() {
  }

}
