<div class="menu">

  <mat-list role="list">
    <!-- login -->
    <mat-list-item role="listitem" *ngIf="!authService.user$.value">
      
      <div class="list-item">
        <span><i class="icon-lg green fas fa-user"></i></span>
        <div>          
          <a class="font-lg dark-blue" mat-button (click)="navigate('/login')">{{ 'MENU.LOGIN.TITLE' | translate }}</a>
          <span>{{ 'MENU.LOGIN.HINT' | translate }}</span>
          <a mat-button  (click)="navigate('/sign-up')">{{ 'MENU.LOGIN.LINK' | translate }}</a>
        </div>
      </div>

    </mat-list-item>

    <!-- user area -->
    <mat-list-item role="listitem" *ngIf="!!authService.user$.value">
      
      <div class="list-item">
        <span><i class="icon-lg green fas fa-user"></i></span>
        <div>          
          <a class="font-lg dark-blue" mat-button>{{ 'MENU.USER.AREA.TITLE' | translate }}</a>
          <a class="font-md dark-blue" mat-button (click)="navigate('/user')">{{ 'MENU.USER.PROFILE.TITLE' | translate }}</a>
          <a class="font-md dark-blue" mat-button (click)="navigate('/user/my-trees')">{{ 'MENU.USER.MY.TREES.TITLE' | translate }}</a>
        </div>
      </div>

    </mat-list-item>

    <!-- news -->
    <mat-list-item role="listitem">
      <div class="list-item" >
        <span><i class="icon-lg green fas fa-newspaper"></i></span>
        <div>
          <a class="font-lg dark-blue" mat-button (click)="navigate('/news')">{{ 'MENU.NEWS.TITLE' | translate }}</a>
        </div>
      </div>  
    </mat-list-item>

    <!-- contacts -->
    <mat-list-item role="listitem">
      <div class="list-item">
        <span><i class="icon-lg green fas fa-envelope"></i></span>
        <div>
          <a class="font-lg dark-blue" mat-button (click)="navigate('/contacts')">{{ 'MENU.CONTACTS.TITLE' | translate }}</a>
        </div>
      </div>     
    </mat-list-item>

    <!-- CM -->
    <mat-list-item role="listitem">
      <div class="list-item" >      
        <a href="https://www.cm-odivelas.pt/" target="_blank">
          <img src="assets/images/cmo_logo.svg" width="152px">
        </a>  
      </div>  
    </mat-list-item>
  </mat-list>

  <!-- sign out -->
  <div class="logout-btn" *ngIf="!!authService.user$.value" (click)="authService.logout()">
    <span><i class="icon-lg light-green fas fa-sign-out-alt"></i></span>
    <div>
      <span class="dark-gray">{{ 'MENU.LOGOUT' | translate }}</span>
    </div>
  </div>  
</div>